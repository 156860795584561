import React, { Component } from "react";
import SyntaxHighlighter from "react-syntax-highlighter";
import logo from "../../assets/img/kh_logo.svg";
import { Container } from "reactstrap";

const codeString = `
///////////////////////////////////////////////
/                   CREDITS                   /
/                                             /
/           Námět  Josef Bátrla               /
/                  Tomáš Páleníček            /
/          Scénář  Josef Bátrla               /
/                  Tomáš Páleníček            /
/           Texty  Josef Bátrla               /
/                  Tomáš Páleníček            /
/           Hudba  Josef Bátrla               /
/     Development  Martin Pilát               /
/          Grafika  Martin Pilát               /
/                  Josef Bátrla               /
/  Special Thanks  Kristýna Gembalová         /
/                  Maroš Sovák                /
/                  Kateřina Žáčková           /
/                  Jiří Helán                 /
/                  Pavel Kroupa               /
/                  Ondřej Hanák               /
/                  Jan Sedláček               /
/                  a celý tým KROUPAHELÁN     /
/             (c)  KROUPAHELÁN                /
/                  advokátní kancelář s.r.o.  /
///////////////////////////////////////////////
`;

class GenerateCredits extends Component {
  frameId = null;
  state = {
    intervalId: null,
    currentCount: 0
  };

  theoreticalComponentAnimationFunction = () => {
    const newCount = this.state.currentCount + 1;
    this.setState({ currentCount: newCount });
  };

  componentDidMount() {
    this.startLoop();
  }

  componentWillUnmount() {
    this.stopLoop();
  }

  startLoop = () => {
    setTimeout(() => {
      this.theoreticalComponentAnimationFunction();
      this.frameId = window.requestAnimationFrame(this.startLoop);
    });
  };

  stopLoop = () => {
    window.cancelAnimationFrame(this.frameId);
  };

  render() {
    return (
      <>
        <Container style={{ "min-height": "90vh" }}>
          <div className="contentBox">
            <div className="container">
              <a href="http://www.kroupahelan.cz/" target="blank">
                <img src={logo} alt="logo" />
              </a>
            </div>
            <SyntaxHighlighter>
              {codeString.substring(0, this.state.currentCount)}
            </SyntaxHighlighter>
            <div className="containerButton">
              <button onClick={() => this.props.changeQuestion("wellcome")}>
                Menu
              </button>
            </div>
          </div>
          <div
            className="navButtons"
            style={{ width: "inherit", justifyContent: "center" }}
          >
            <a
              href="https://www.facebook.com/kroupahelan/?ref=br_rs"
              target="_blank"
            >
              <button className="navButton">Facebook</button>
            </a>
            <a href="https://twitter.com/KAH_IT_IP" target="_blank">
              <button className="navButton">Twitter</button>
            </a>
            <a href="http://www.kroupahelan.cz/kontakt" target="blank">
              <button className="navButton">Kontakt</button>
            </a>
            <button
              className="navButton"
              onClick={() => {
                this.props.toggleSound();
              }}
            >
              {this.props.buttonNavText}
            </button>
          </div>
          <style>{`
            pre {
              //font-family: Ubuntu, "Press Start 2P", cursive !important;
              //font-size: 1.3rem;
              //font-weight: 500;
              background: rgba(0, 0, 0, 0) !important;
              border: 0px  !important;
              max-width: 100vw !important;
            }
          `}</style>
        </Container>
        <div className="containerVersion">
          <p>BETA 1.0</p>
        </div>
      </>
    );
  }
}
export default GenerateCredits;
