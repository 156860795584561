import React, { Component } from "react";
import GenerateButtons from "../GenerateButtons";

import { Container } from "reactstrap";

class GenerateQuestions extends Component {
  render() {
    return (
      <>
        <Container style={{ "min-height": "90vh" }}>
          <div className="contentBox">
            {this.props.questionText()}
            <div className="containerButton">
              <GenerateButtons
                changeQuestion={this.props.changeQuestion}
                currentQuestion={this.props.currentQuestion}
              />
            </div>
          </div>
          <div className="navButtons" style={{ width: "inherit" }}>
            <button
              className="navButton"
              onClick={() => {
                this.props.toggleSound();
              }}
            >
              {this.props.buttonNavText}
            </button>
          </div>
        </Container>
        <div className="containerVersion">
          <p>BETA 1.0</p>
        </div>
      </>
    );
  }
}

export default GenerateQuestions;
