import React, { Component } from "react";
import Sound from "react-sound";
import "./App.css";
import data from "./data";
import TextFormatter from "./components/TextFormatter";
import GenerateQuestion from "./components/Questions/GenerateQuestions";
import GenerateLanding from "./components/Landing/GenerateLanding";
import GenerateCredits from "./components/Credits/GenerateCredits";

const WELLCOME = "wellcome";
const CREDITS = "credits";
const TRACK_LANDING =
  "https://videogamelaw.cz/domains/videogamelaw.cz/assets/media/intro.mp3";
const TRACK_GAME =
  "https://videogamelaw.cz/domains/videogamelaw.cz/assets/media/game.mp3";
const TRACK_CREDITS =
  "https://videogamelaw.cz/domains/videogamelaw.cz/assets/media/outro.mp3";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentQuestion: WELLCOME,
      currentSong: null,
      loop: false,
      playStatus: Sound.status.PLAYING,
      volume: 100,
      muted: false,
      loop: true,
      mutteButtonText: "Mute"
    };
  }

  changeQuestion = question => {
    this.setState({ currentQuestion: question });
  };

  generateQuestionText = () => {
    return data.questions[this.state.currentQuestion].text.map(
      (text, index) => (
        <TextFormatter key={index} text={text.text} inStyles={text.type} />
      )
    );
  };

  playSong = () => {
    return (
      <Sound
        url={this.state.currentSong}
        playStatus={this.state.playStatus}
        volume={this.state.volume}
        loop={this.state.loop}
      />
    );
  };

  muteTrack = () => {
    this.setState({
      volume: this.state.volume === 100 ? 0 : 100,
      mutteButtonText: this.state.mutteButtonText === "Mute" ? "Unmute" : "Mute"
    });
  };

  render() {
    const currentQuestion = this.state.currentQuestion;

    switch (currentQuestion) {
      case WELLCOME:
        this.state.currentSong !== TRACK_LANDING &&
          this.setState({ currentSong: TRACK_LANDING });

        return (
          <>
            <GenerateLanding
              changeQuestion={this.changeQuestion}
              currentQuestion={this.state.currentQuestion}
              questionText={this.generateQuestionText}
              toggleMute={this.muteTrack}
            />
            {this.playSong()}
          </>
        );
      case currentQuestion !== WELLCOME && currentQuestion !== CREDITS
        ? currentQuestion
        : null:
        this.state.currentSong !== TRACK_GAME &&
          this.setState({ currentSong: TRACK_GAME });
        this.playSong();
        return (
          <>
            <GenerateQuestion
              changeQuestion={this.changeQuestion}
              currentQuestion={this.state.currentQuestion}
              questionText={this.generateQuestionText}
              toggleSound={this.muteTrack}
              buttonNavText={this.state.mutteButtonText}
            />
            {this.playSong()}
          </>
        );
      case CREDITS:
        this.state.currentSong !== TRACK_CREDITS &&
          this.setState({ currentSong: TRACK_CREDITS });
        this.playSong();
        return (
          <>
            <GenerateCredits
              changeQuestion={this.changeQuestion}
              toggleSound={this.muteTrack}
              buttonNavText={this.state.mutteButtonText}
            />
            {this.playSong()}
          </>
        );
      default:
        return null;
    }
  }
}

export default App;
