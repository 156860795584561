import React from "react";

const styles = {
  normal: {},
  left: {
    display: "flex",
    justifyContent: "flex-start",
    float: "left",
    maxWidth: "75%",
    border: "0px solid",
    padding: "20px",
    background: "white",
    boxShadow: "-5px 10px",
    margin: "5px 0 30px"
  },
  right: {
    display: "flex",
    justifyContent: "flex-end",
    float: "right",
    maxWidth: "75%",
    border: "0px solid",
    padding: "20px",
    background: "lightgray",
    boxShadow: "5px 10px",
    margin: "5px 0 30px"
  }
};

const FormatText = ({ text, inStyles }) => {
  return (
    <div className="clearfix">
      <div style={styles[inStyles]}>
        <span>
          <p>{text}</p>
        </span>
      </div>
    </div>
  );
};

export default FormatText;
