import React from "react";

const defText = inputText => ({ text: inputText, type: "normal" });
const defLQuote = inputText => ({ text: inputText, type: "left" });
const defRQuote = inputText => ({ text: inputText, type: "right" });

const data = {
  questions: {
    wellcome: {
      text: [defText("Vítejte u první právní videohry z dílny")],
      answers: [{ text: "Let's roll!", nextQuestion: "1_1_1" }]
    },
    "1_1_1": {
      text: [
        defText(`Sedíš ve své garáži, ze které řídíš svoje vývojářské impérium. 
          No impérium, pokud se za impérium počítá stůl, počítač, nulovej nápad 
          a vůle vytvořit něco jedinečného.`),
        defText(
          `Za cíl sis stanovil jediné, vytvořit hru, která tě proslaví, přinese 
          investora a zařídí ti spokojený život. Někde však musíš začít.`
        )
      ],
      answers: [
        { text: "Dám si cigárko a uvidím co se stane", nextQuestion: "1_1_2" },
        { text: "Zkontroluju e-mail", nextQuestion: "1_1_3" },
        { text: "Zkontroluju telefon", nextQuestion: "1_1_4" }
      ]
    },
    "1_1_2": {
      text: [
        defText(
          `Cigárko je vždycky dobrý nápad (no úplně ne), ale zatím tě nic moc jiného 
          nenapadlo. Pojďme se posunout dál.`
        )
      ],
      answers: [
        { text: "Zkontroluju e-mail", nextQuestion: "1_1_3" },
        { text: "Zkontroluju telefon", nextQuestion: "1_1_4" }
      ]
    },
    "1_1_3": {
      text: [
        defText(
          "Mrkl jsi na e-mail a hned tě zaujala zpráva od tvého „kolegy“ Franty."
        ),
        defText(`„Ahoj Honzo, hned jak budeš moci, zavolej mi – konečně mám nápad za milion. 
          Slyšel jsi někdy o Pandrzejovi Hrabkowskim? Napsal skvělou knihu „Zahýbač“, 
          poměrně neznámá věc, ale napadlo mě skvělý RPG, který bychom mohli dát do kupy… 
          V příloze ti zasílám základní dějovou linii, názvy postav a jejich popisy přesně 
          tak, jak je to v knize.`),
        defText(
          "Zároveň ti posílám grafiku postav, myslím si, že se mi to docela povedlo."
        ),
        defText("Dej se do toho."),
        defText("Franta“"),
        defText(`Přečetl sis scénář a bylo to docela zajímavé. Na tomhle by šel vydělat milion. 
          Něco se ti ale nezdá, přece jen je to nějaká knížka. Nemůže být z toho problém?`)
      ],
      answers: [
        { text: "Ne, je to v pohodě, dám se do práce", nextQuestion: "1_2_1" },
        {
          text: "Vygooglím si aspoň, co je to za autora",
          nextQuestion: "1_2_2"
        },
        { text: "Poradím se s právníkem", nextQuestion: "1_2_3" }
      ]
    },
    "1_1_4": {
      text: [
        defText(`Mrkl jsi na telefon. Kromě dvou nikam nevedoucích spojení na Tinderu a SMS od mámy, 
          že večeře je hotová (správně, bydlíš totiž pořád u rodičů) se nic zajímavého nestalo.`)
      ],
      answers: [{ text: "Zkontrolovat e-mail", nextQuestion: "1_1_3" }]
    },
    "1_2_1": {
      text: [
        defText(`Jop, betaverze nevypadá vůbec špatně. Hlavní postava Berald je docela slušný „Zahýbač“ a s jeho dvěma meči mu to docela jde. 
          Podařilo se ti poskládat relativně zajímavej příběh poskládanej z popisu jednotlivých postav.`),
        defText(`Na netu jsi našel ještě docela zajímavou hudbu. Dokonce byla pod veřejnou licencí, protože u ní bylo napsáno „CC“ a pak 
          nějaké další divné věci jako „-BY-NC-ND 3.0“, ale to už tě nezajímá. CC jako creative commons znáš, protože na tom přece běží 
          Wikipedie - a ta je super zadarmo.`)
      ],
      answers: [{ text: "Vydat hru", nextQuestion: "1_3_1" }]
    },
    "1_2_2": {
      text: [
        defText(`Pandrzej Hrabkowski? Starej polskej autor s hodně zajímavým knírem. Hm…stále žijící. 
          Co s tím? Může tam být nějaký problém?`)
      ],
      answers: [
        { text: "Ne, je to v pohodě, dám se do práce", nextQuestion: "1_2_1" },
        { text: "Poradím se s právníkem", nextQuestion: "1_2_3" },
        { text: "Kontaktuju Pandrzeje", nextQuestion: "1_2_4" }
      ]
    },
    "1_2_3": {
      text: [
        defText(`Táta ti poradil právníka, kterej se staral o nějakou zpracovatelskou smlouvu s jeho 
          IT dodavatelem. Právník super. To se Zahýbačem to vypadá zle.`),
        defText(`Ok, evidentně si nemůžu jen tak random půjčit příběh (byť jen jako námět), postavy, jejich 
          názvy a popisy jen tak, ale musím mít nějakou licenční smlouvu, prostřednictvím které mi 
          Pandrzej udělí svolení „zpeněžit jeho dílo“.`),
        defText(
          `No, chtělo by to jít za Pandrzejem s předem připravenou smlouvou. Otázka je, jestli 
          k tomu fakt potřebuju právníka…`
        )
      ],
      answers: [
        {
          text: "Napíšu si smlouvu sám a jdu za Pandrzejem",
          nextQuestion: "1_2_5"
        },
        {
          text: "Nechám si napsat smlouvu od právníka a jdu za Pandrzejem",
          nextQuestion: "1_2_6"
        }
      ]
    },
    "1_2_4": {
      text: [
        defLQuote(`„Haló, tady Honza.“`),
        defRQuote(`„Haló, tady pan Hrabowski.“`),
        defLQuote(`„Pane Hrabowski, moc se mi líbí Vaše kniha. Co byste řekl na to, že by vznikla 
          hra podle Vašeho příběhu o Zahýbači? Myslím, že by to mohlo být super, kdyby ty Vaše postavičky 
          takto ožily. Co si myslíte? Já bych pak měl velmi úspěšnou hru a zase bych ty Vaše knihy zviditelnil.“`),
        defRQuote(`„Tak zaprvé, říká Vám něco copyright? Slyšel jste někdy o výhradní licenci? 
          Akorát marníte můj čas! Až se vzpamatujete, tak s Váma možná začnu mluvit.“`)
      ],
      answers: [
        {
          text: "Kašlat na to, vše je v pohodě, dám se do práce",
          nextQuestion: "1_2_1"
        },
        {
          text: "Poradím se s právníkem",
          nextQuestion: "1_2_3"
        }
      ]
    },
    "1_2_5": {
      text: [
        defText(`Díky poradě s právníkem vím, co a jak, tak přece nebudu utrácet peníze za sepis nějakého papíru. 
          Konec konců i ten Pandrzej se na to, jak byl na začátku komunikace dotčenej, docela usmíval. 
          Skoro to vypadá, že mu to udělalo radost. Hmm, divný, ale co už.`),
        defText(`Na netu jsi našel ještě docela zajímavou hudbu. Dokonce byla pod veřejnou l
        icencí, protože u ní bylo napsáno „CC“ a pak nějaké další divné věci jako „-BY-NC-ND 3.0“, ale 
        to už tě nezajímá. CC jako creative commons znáš, protože na tom přece běží Wikipedie - a ta je super zadarmo.`)
      ],
      answers: [{ text: "Poslat hru do světa", nextQuestion: "1_4_1" }]
    },
    "1_2_6": {
      text: [
        defText(`Pandrzej se netvářil moc bohulibě, ale zaplať Pán Bůh, že jsem si nechal tu smlouvu napsat právníkem, 
          který mě podrobně zasvětil do všech možných problémů, které se licenční smlouvy týkají. Už jen způsob 
          sjednání odměny má docela konsekvence.`),
        defText(`Ještě že jsem si tu smlouvu nepsal sám.`),
        defText(`Na netu jsi našel ještě docela zajímavou hudbu. Dokonce byla pod veřejnou licencí, protože 
        u ní bylo napsáno „CC“ a pak nějaké další divné věci jako „-BY-NC-ND 3.0“, ale to už tě nezajímá. CC 
        jako creative commons znáš, protože na tom přece běží Wikipedie - a ta je super zadarmo.`)
      ],
      answers: [{ text: "Poslat hru do světa", nextQuestion: "1_5_1" }]
    },
    "1_3_1": {
      text: [
        defText(
          `Vydání hry proběhlo docela fajn. Dokud ti nepřišly hned dvě žaloby najednou.`
        ),
        defText(`První žaloba je od Pandrzeje Hraboskeho. Vydáním hry, která byla založena 
          na konkrétní knize (byť jen námět, název postav apod.), jsi zasáhl do jeho 
          autorských práv. Pan Hrabowski má teď podle příslušných zákonů vůči tobě nárok 
          na vydání bezdůvodného obohacení.`),
        defText(
          `A kolik, že je to bezdůvodné obohacení? Dvojnásobek hodnoty licence…`
        ),
        defText(
          `Druhá žaloba se týká hudby. Nebylo to snad CC? Proč teda? Vždyť je to veřejná licence, ta je zadarmo.`
        ),
        defText(`Oh, aha, ty divný písmenka „-BY-NC-ND 3.0“ znamenají, že hudbu nemůžeš použít pro 
          komerční účely, tzn. ji umístit do hry a tu poté prodávat.`),
        defText(
          `Nedá se nic dělat. Musel jsi oběma zaplatit. Po zaplacení jsou všichni spokojení a povede se ti 
          uzavřít s panem Hrabowskim licenční smlouvu, aby jsi hru mohl vydat legálně a dokonce abys mohl vydat i pokračování.`
        )
      ],
      answers: [{ text: "Pokračovat", nextQuestion: "2_1_1" }]
    },
    "1_4_1": {
      text: [
        defText(`Hra má úspěch. Fakticky! Prodeje docela fungují, víc, než se čekalo. Odměna Pandrzejovi 
          z licenční smlouvy se zaplatila sama o sobě několikrát. Tohle je sen.`),
        defText(`Hm. Pandrzej volá?`),
        defRQuote(
          `„Nazdar mládežníku, koukám, že hře se daří. To jsem sám nečekal. Je čas se pobavit o mé dodatečné odměně“.`
        ),
        defLQuote(
          `„Ale pane Pandrzeji, odměna již byla zaplacena, jednorázově, dle námi podepsané smlouvy“.`
        ),
        defRQuote(`„No jo kamaráde, ale to já nevěděl, že to bude mít takový úspěch. 
          Ze zákona mi při jednorázové odměně náleží dodatečná odměna, pokud se původní 
          odměna ukáže být malá. A já svou dodatečnou odměnu pochopitelně chci“.`),
        defText(
          `Je to tak, máš problém. Ale není to jediný problém. Ve schránce jsi našel žalobu.`
        ),
        defText(
          `Žaloba týkající se CC? Proč? Vždyť je to veřejná licence, ta je zadarmo.`
        ),
        defText(
          `Oh, aha, ty divný písmenka „-BY-NC-ND 3.0“ znamenají, že hudbu nemůžeš použít pro komerční 
          účely, tzn. ji umístit do hry a tu poté prodávat.`
        ),
        defText(
          `Jak autor knihy, tak autor hudby se domáhají nějakých peněz, které ti nezbývá, než zaplatit.`
        ),
        defText(`Zkus si příště dát pozor.`)
      ],
      answers: [{ text: "Pokračovat", nextQuestion: "2_1_1" }]
    },
    "1_5_1": {
      text: [
        defText(`Hra má úspěch, fakticky! Prodeje docela fungují, víc, než se čekalo. Odměna 
          Pandzrejovi z licenční smlouvy se zaplatila sama o sobě několikrát. Tohle je sen.`),
        defText(`Hm. Pandrzej volá?`),
        defRQuote(
          `„Nazdar mládežníku, koukám, že hře se daří – to jsem sám nečekal. Je čas se pobavit o mé dodatečné odměně“`
        ),
        defLQuote(`„Ale pane Pandzreji, odměna již byla zaplacena, odměnu jsme si sjednali výnosově, 
        dle námi podepsané smlouvy. Dobře jste věděl do čeho jdete.“`),
        defRQuote(
          `„No jo kamaráde, ale to já nevěděl, že to bude mít takový úspěch.“`
        ),
        defLQuote(`„Promiňte Pandzreji, ale takhle přece funguje výnosová odměna, Zahýbač měl 
          úspěch, ale o to více jste dostal pěněz přece i Vy, nezlobte se.“`),
        defText(
          `Uff, to bylo vo fous. Ještě, že mě na tuto eventualitu právník upozornil. Takhle se to vyřešilo docela samo.`
        ),
        defText(`Co se ale nevyřešilo samo, je žaloba ve schránce.`),
        defText(
          `Žaloba týkající se CC? Proč? Vždyť je to veřejná licence, ta je zadarmo.`
        ),
        defText(`Oh, aha, ty divný písmenka „-BY-NC-ND 3.0“ znamenají, že hudbu nemůžeš použít 
          pro komerční účely, tzn. ji umístit do hry a tu poté prodávat.`),
        defText(
          `Autor hudby se domáhá nějakých peněz, které ti nezbývá, než zaplatit.`
        ),
        defText(`Zkus si příště dát pozor.`)
      ],
      answers: [{ text: "Pokračovat", nextQuestion: "2_1_1" }]
    },
    "2_1_1": {
      text: [
        defText(`Tvá hra Zahýbač si vedla nad očekávání dobře! Kdo by to byl řekl? 
          Tenhle rozjetej vlak nemá smysl zastavovat. Hned se dáš do plánování pokračování - Zahýbač 2 bude pecka.`),
        defText(`Jenže to asi nepůjde jenom tak. Hlavou se ti motá tolik myšlenek. 
          Máš na další díl dostatek peněz? A co propagace? No, tohle bude chtít 
          trochu víc přemýšlení a mámina meruňkového táče. 
          Možná by nebylo špatné trochu vyčistit vzduch.`)
      ],
      answers: [
        { text: "Dám si pořádnýho chrupíka", nextQuestion: "2_1_2" },
        { text: "Zkontroluju poštu", nextQuestion: "2_1_3" },
        { text: "Zkontroluju telefon", nextQuestion: "2_1_4" }
      ]
    },
    "2_1_2": {
      text: [
        defText(`Prokrastrubace a chrupík – nejlepší přátelé kreativity. 
          Ale teď už by bylo fakt dobré začít něco dělat. Nebo ne?`)
      ],
      answers: [
        { text: "Zkontroluju poštu", nextQuestion: "2_1_3" },
        { text: "Zkontroluju telefon ", nextQuestion: "2_1_4" }
      ]
    },
    "2_1_3": {
      text: [
        defText(`Složenka, složenka, složenka – máma a táta;`),
        defText(`Předplatné Playboye – ty;`),
        defText(`Reklamní leták  – odpaďák;`),
        defText(`Obsílka – táta.`),
        defText(`Wait, obsílka? To asi nebude táta, táta je totiž inženýr a ne 
        bakalář (jak mi moc rád pokaždé připomíná). Tohle nevypadá dobře.`),
        defText(`„Předžalobní výzva…“ Oh boy.`),
        defText(
          `„Zásah do autorských práv…“ To ne, vždyť s Pandrejem jseš vyrovnanej.`
        ),
        defText(`„Návrh řešení…“ OMG TO NE!`),
        defText(`„Tvůj Franta“`),
        defText(`Je tohle možné? Evidentně jen proto, že mi Franta v začátku pomohl 
          s grafikou, má vůči Zahýbači taky nějaká práva... A evidentně mu dlužím 
          spoustu peněz a jestli chci prodávat merch…`),
        defText(`Jeho řešení?`),
        defText(`Založit společně s.r.o. s investorem Vikim Koženkovým, který projekt 
          zafinancuje a zároveň my převedeme související práva na tuhle společnost. 
          Well, to není zase tak špatný… neboooo?`)
      ],
      answers: [{ text: "Zavolám Frantovi", nextQuestion: "2_2_1" }]
    },
    "2_1_4": {
      text: [
        defText(`Telefon po mámě nic moc, ale co už, hlavně, že máš přes co koukat 
          na meméčka, když ti máma vypne WI-FI.`),
        defText(`Nepřijatý hovor od Franty? To už je asi desátý za poslední měsíc – ten 
          člobrda je docela neodbytnej, na to, že jsi mu ani jednou nezavolal zpět.`),
        defText(
          `Oh, co to je? Aha, volá ti Franta, zrovna teď. Co se dá dělat - zvedneš to.`
        ),
        defLQuote(`„Nazdar, Franto“`),
        defRQuote(`„Nazdar, čumáčku“`),
        defText(
          `Ehh, zdá se ti to, nebo ti Franta řekl čumáčku? Buď to, anebo to bylo něco hodně podobnýho...`
        ),
        defLQuote(`„Co se děje Františku?“`),
        defRQuote(
          `„Koukám, že kromě zvedání telefonu ti ani vybírání pošty moc nejde“`
        ),
        defLQuote(`„Vybírání pošty? He?“`),
        defText(`„…“ Položil to…`)
      ],
      answers: [{ text: "Zkontrolovat poštu", nextQuestion: "2_1_3" }]
    },
    "2_2_1": {
      text: [
        defLQuote(`„Ok Franto, chápu to dobře, že tvoje řešení tyhle situace je v tom, že 
          spoluzaložíme s.r.o. s nějakým Vikim Koženkovým, na kterou převedeme všechna autorská
          práva k Zahýbačovi?“`),
        defRQuote(
          `„Chápeš to naprosto přesně. Oba máme na možným úspěchu Zahýbače podíl – to nepopřeš.“`
        ),
        defLQuote(`„Fajn, vole…“`),
        defRQuote(`„Tak super, nechci, abys na tom byl bitej hele, takže dáme si ten podíl nějak 
          spravedlivě, ty 40, já 30 a investor taky 30. A abys nebyl hořkej, to s.r.o. 
          založím a o všechny papíry se postarám já, jo?“`),
        defLQuote(`„Jaký papíry?“`),
        defRQuote(
          `„No licenční smlouvy, živnostňák, založení společnosti, smlouvy mezi společníky…" [dál jsi přestal poslouchat]`
        )
      ],
      answers: [
        { text: "„To si piš, že postaráš.“", nextQuestion: "2_2_2" },
        {
          text: "„Ne, díky, o administrativu se postarám já.“",
          nextQuestion: "2_2_3"
        }
      ]
    },
    "2_2_2": {
      text: [
        defLQuote(
          `„To si piš, že postaráš – mám starosti o samotnou hru, takže zdarec.“`
        ),
        defText(`No, nakonec to nebylo tak špatné. Investor Viki Koženkový dodá prachy, 
          Franta se o všechno postará a mě stačí dotáhnout do konce celou hru 
          a dodat ji na trh. Nakonec, co je to s.r.o., trochu tuším, ale jak ho 
          založit to, je mi fuk. A koneckonců mám i největší podíl ve společnosti, takže za mě dobrý.`),
        defText(`Nakonec to Frantovi ani netrvalo dlouho, sešli jsme se u notáře, udělal 
          jsem pár podpisů na několika papírech a bylo to.`)
      ],
      answers: [
        { text: "Přečíst si, co jsem podepsal", nextQuestion: "2_2_4" },
        { text: "Pokračovat ve vývoji hry", nextQuestion: "2_2_5" }
      ]
    },
    "2_2_3": {
      text: [
        defLQuote(
          `„Zapomeň, založení s.r.o. i nastavení vztahů s Koženkovým pojede pěkně v mé režii.“`
        ),
        defRQuote(`„Fajn, dělej, jak myslíš, chtěl jsem ti pomoci, čus.“`),
        defText(`Pomoci, to jo. Jenže já vlastně vůbec nevím, jak se zakládá společnost. Licenční smlouvy? 
          To už teď trochu vím, ale všechny ty další papíry…Ani nevím, jak to udělat s tím investorem. 
          Kéž bych dával pozor ve škole.`)
      ],
      answers: [
        { text: "Pogooglím, co a jak", nextQuestion: "2_2_6" },
        { text: "Zavolám právníkovi", nextQuestion: "2_2_7" }
      ]
    },
    "2_2_4": {
      text: [
        defText(`„Společenská smlouva…“eh`),
        defText(`„notářský zápis…“ eh`),
        defText(`„vedlejší ujednání…“ eh`),
        defText(`„postoupení práv z licence…“ eh`),
        defText(`Přestaň si lhát do kapsy, tohle fakt řešit nebudeš.`)
      ],
      answers: [{ text: "Pokračovat ve vývoji hry", nextQuestion: "2_2_5" }]
    },
    "2_2_5": {
      text: [
        defText(`Papíry vyřešeny jakž takž, aspoň tak mi to potvrdil Franta – parádička. 
          Zahýbač 2 je na tom topově. `),
        defText(`Nejlepší RPG tohoto měsíce. Život nemohl být lepší. Už teď se těším 
          na třetí díl, kterej už má Franta napsanej, tohle bude prostě topovka na druhou. 
          Zavolám Frantovi, jak si zatím užívá svoje peníze, a sdělím mu svoje plány.`)
      ],
      answers: [{ text: "Zavolat Frantovi", nextQuestion: "2_3_1" }]
    },
    "2_2_6": {
      text: [
        defText(`Strejda Google je top. Na stránkách zalozsipodnikanivparkrocich.cz jsi 
          zjistil, co jsi potřeboval. Trvalo ti to sice jen dva dny, než jsi se odhodlal 
          to přečíst, a další tři dny, než jsi to pochopil, ale je to tam.`),
        defText(`Nějak polepit ty další smlouvy, jo, to nějak půjde…`),
        defText(`Franta ti po telefonu vysvětlil, co máš udělat, a tak jsi to udělal. 
          Jsi vlastníkem 40% podílu ve společnosti Zahýbač s.r.o.`),
        defText(
          `Teď už ti nebrání nic jiného než pokračovat ve své skutečné práci, Zahýbač 3 - Divoký ohon.`
        )
      ],
      answers: [{ text: "Pokračovat ve vývoji hry", nextQuestion: "2_2_5" }]
    },
    "2_2_7": {
      text: [
        defText(`Ty vole…Není od věci si nechat poradit. Jedna hodina s právníkem tě dost možná 
          zachránila od dobrýho průseru.`),
        defText(`Jedna věc je samotný založení s.r.o., to prý průměrně zdatnej člověk zvládne sám. 
          To prej problém není.`),
        defText(
          `Co už trochu problém je, je vyřešení vztahů mezi společníky, postoupení licence/sublicence.`
        ),
        defText(`Nad čím jsi úplně nepřemýšlel, je to, že v jeden okamžik to může Franta klidně zabalit a prodat svůj 
          podíl, popřípadě tě může vyštípat.`),
        defText(
          `A tihle právníci jsou docela tlustí, takže evidentně se jim dobře daří. `
        ),
        defText(`Volba není jednoduchá.`)
      ],
      answers: [
        {
          text: "Pogooglit, jak založit s.r.o. a vyřešit související vztahy",
          nextQuestion: "2_2_6"
        },
        { text: "Nechat si všechno vyřešit od právníků", nextQuestion: "2_2_8" }
      ]
    },
    "2_2_8": {
      text: [
        defText(`Okej, stálo to nějaký peníze a Franta i Viki Koženkový byli trochu hořcí 
          a chtělo to trošku handrkování a vyjednávání. Na druhou stranu máš od právníků 
          potvrzené, že všechno je v pohodě.`),
        defText(
          `Mezitím Zahýbač 2 je na tom topově. Nejlepší RPG tohoto měsíce, život nemohl být lepší.`
        ),
        defText(
          `Proč nepokračovat dalším dílem? Zahýbač 3 – Divoký ohon už má jasnější 
          kontury, tohle bude prostě topovka na druhou. Zavolej Frantovi, jak si 
          zatím užívá svoje peníze, a sděl mu svoje plány.`
        )
      ],
      answers: [{ text: "Zavolat Frantovi", nextQuestion: "2_4_1" }]
    },
    "2_3_1": {
      text: [
        defLQuote(`„Čau Franto, tak jseš připravenej na Zahýbače 3?“`),
        defRQuote(`„Mnooooo, co se toho týče, z toho asi nic moc nebude.“`),
        defLQuote(`„???“`),
        defRQuote(`„Domluvil jsem se s Vikim, že na to kašleme. Ukázalo 
        se, že Viki zainvestoval do Hubísoftu a že Zahýbač trochu 
        konkuruje jejich titulu Mokasín Speed“`),
        defLQuote(`„???“`),
        defRQuote(`„Je mi to líto kámo, tohle je konec.“`)
      ],
      answers: [
        { text: "Poradit se s právníkem", nextQuestion: "2_3_2" },
        {
          text: "Vytvořit Zahýbače 3 – Divoký ohon anyway",
          nextQuestion: "2_3_3"
        }
      ]
    },
    "2_3_2": {
      text: [
        defText(`„Je mi líto.“ Pozoruhodné slyšet tuto větu z úst právníka.`),
        defText(`Evidentně bylo přinejmenším diskutabilní rozhodnutí nenechat 
          administrativu na právnících. Trochu jsi zapomněl, že jste s Frantou 
          veškerá práva k Zahýbačovi převedli na společnost Zahýbač s.r.o., ve 
          které jste dále tvořili jako zaměstnanci, a tudíž už k dílu nemáte přístup.`),
        defText(
          `Tohle vypadá jako ne úplně dobrej konec. Alespoň, že mám peníze ze Zahýbače 1 a 2.`
        )
      ],
      answers: [{ text: "Credits", nextQuestion: "credits" }]
    },
    "2_3_3": {
      text: [
        defText(`Kašleš na to. To ty jsi Zahýbače rozpohyboval, to Franta napsal scénář 
          k jedničce i dvojce. Nikdo, ani Viki ti nebude říkat, co můžeš nebo nemůžeš 
          se svým dítkem dělat.`),
        defText(`Zvoní ti telefon. Neznámé číslo. OK melodie ‘We are the champions’ 
          není v tuhle chvíli nejlepší vyzvánění:`)
      ],
      answers: [
        { text: "Zvednu telefon", nextQuestion: "2_3_4" },
        { text: "Nechám to spadnout do hlasovky", nextQuestion: "2_3_5" }
      ]
    },
    "2_3_4": {
      text: [
        defLQuote(`„Sim“`),
        defRQuote(`„Tady Viki Koženkový“`),
        defLQuote(`„…“`),
        defRQuote(`„Jen jsem ti chtěl, mladej, říct, že jsi skončil. Nedělá mi to velkou radost, 
          ale takovej je prostě business. Ber to z té lepší stránky, příště budeš chytřejší.“`),
        defLQuote(`„Skončils, čumáku?“ Opravdu jsem chtěl říct čumáku?`),
        defRQuote(`„Jop, Franta se zmínil, že bys rád pokračoval na vlastní pěst. Dovol mi, abych ti 
          sdělil, že od teď jseš pod mým drobnohledem. Budu sledovat každej tvůj krok a ve 
          chvíli, kdy jen pomyslíš, že bys vytvořil další díl Zahýbače, pošlu na tebe právníky 
          a už si neškrtneš.“`),
        defText(`No, tohle nedopadlo úplně dobře. Tohle asi bude konec...`)
      ],
      answers: [{ text: "Credits", nextQuestion: "credits" }]
    },
    "2_3_5": {
      text: [
        defText(`Jop, tvoje prokrasturbace ti nedovolila zrušit něco tak otravného (a devadesátkového) 
          jako je hlasovka. Díky tomu jsi měl aspoň možnost si vyslechnout zajímavou zprávu:`),
        defText(`„Tady Viky Koženkový.`),
        defText(`Jen jsem ti chtěl, mladej, říct, že jsi skončil. Nedělá mi to velkou radost, ale 
          takovej je prostě business. Ber to z té lepší stránky, příště budeš chytřejší.`),
        defText(`Jo a Franta se zmínil, že bys rád pokračoval na vlastní pěst. Dovol mi, abych ti 
          sdělil, že od teď jseš pod mým drobnohledem. Budu sledovat každej tvůj krok a ve 
          chvíli, kdy jen pomyslíš, že bys vytvořil další díl Zahýbače, pošlu na tebe právníky 
          a už si neškrtneš.“`),
        defText(`No, tohle nedopadlo úplně dobře. Tohle asi bude konec...`)
      ],
      answers: [{ text: "Credits", nextQuestion: "credits" }]
    },
    "2_4_1": {
      text: [
        defLQuote(`„Čau Franto, tak jseš připravenej na Zahýbače 3?“`),
        defRQuote(`„Mnooooo, co se toho týče, z toho asi nic moc nebude.“`),
        defLQuote(`„???“`),
        defRQuote(`„Domluvil jsem se s Vikim Koženkovým, že na to kašleme. Ukázalo se, že 
          Viki zainvestoval do Hubísoftu a že Zahýbač trochu konkuruje jejich titulu Mokasín Speed.“`),
        defLQuote(`„???“`),
        defRQuote(`„Je mi to líto kámo, tohle je konec.“`)
      ],
      answers: [
        { text: "Poradit se s právníkem", nextQuestion: "2_4_2" },
        { text: "Nasrat", nextQuestion: "2_4_3" }
      ]
    },
    "2_4_2": {
      text: [
        defText(`Musíš si sám sobě přiznat, že ses trochu lekl. Žádný drama, oznámil ti právník. 
        Na tohle bylo od počátku pamatováno. V rámci vedlejších ujednání ve smlouvě se 
          svýma výbornýma společníkama máš toto pořešené. Pořešené to máš aji v licenčních smlouvách.`),
        defText(
          `Zahýbač 3 prostě bude, ať už je to Koženkovýmu a Frantovi po chuti či nikoliv.`
        ),
        defText(`„Ne Franto, tohle není konec.“`),
        defText(`Ve skutečnosti to je konec, ale konec je to dobrý.`)
      ],
      answers: [{ text: "Credits", nextQuestion: "credits" }]
    },
    "2_4_3": {
      text: [
        defText(`„Nasrat.“`),
        defText(
          `Zahýbač je tvoje dítě, trojka vyjde, ať už je to Koženkovýmu a Frantovi po chuti či nikoliv.`
        ),
        defText(
          `Moc dobře si totiž pamatuješ, že přesně tuhle věc jsi s právníky vyřešil.`
        ),
        defText(`„Ne Franto, tohle není konec.“`),
        defText(`Ve skutečnosti to je konec, ale konec je to dobrý.`)
      ],
      answers: [{ text: "Credits", nextQuestion: "credits" }]
    }
  }
};

export default data;
