import React from 'react';
import data from '../data';
import AnswerButtons from './AnswerButton';

const GenerateButtons = ({changeQuestion, currentQuestion}) => (
  data.questions[currentQuestion].answers.map( (answer, index) =>
    <AnswerButtons 
      key={index}
      changeQuestion={changeQuestion}
      nextQuestion={answer.nextQuestion}
      text={answer.text}
    />
  )
)

export default GenerateButtons