import React, { Component } from "react";
import "../Landing/style.css";
import logo from "../../assets/img/kh_logo.svg";
import { Container } from "reactstrap";

class GenerateLanding extends Component {
  render() {
    return (
      <>
        <Container style={{ "min-height": "90vh" }}>
          <div className="container">
            <div className="container" style={{ "padding-bottom": "20px" }}>
              {this.props.questionText()}
              <a href="http://www.kroupahelan.cz/" target="blank">
                <img src={logo} alt="logo" />
              </a>
            </div>
            <div className="contentBox">
              <p
                className="p"
                onClick={() => this.props.changeQuestion("1_1_1")}
              >
                Start
              </p>
              <p className="p" onClick={() => this.props.toggleMute()}>
                Toggle Mute
              </p>
              <p
                className="p"
                onClick={() => this.props.changeQuestion("credits")}
              >
                Credits
              </p>
              <p>
                <a
                  href="https://www.facebook.com/kroupahelan/?ref=br_rs"
                  target="_blank"
                >
                  Facebook
                </a>
              </p>
              <p>
                <a href="https://twitter.com/KAH_IT_IP" target="_blank">
                  Twitter
                </a>
              </p>
              <p style={{ "margin-bottom": "0px" }}>
                <a href="http://www.kroupahelan.cz/kontakt" target="blank">
                  Kontakt
                </a>
              </p>
            </div>
          </div>
        </Container>
        <div className="containerVersion">
          <p>BETA 1.0</p>
        </div>
      </>
    );
  }
}

export default GenerateLanding;
